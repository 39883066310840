import { Box, BoxProps, Typography } from "@mui/material";
import React from "react";
import InfoIcon from "./images/InfoIcon";

 const WarningBox: React.FC<{}> = () => {
    var msg:String="Om du är uppkopplad via VPN, Tor eller liknande anonymiseringstjänst kommer du inte att kunna utföra alla typer av ärenden. För att du ska få tillgång till alla tjänster behöver du logga in utan en sådan uppkoppling."
    return (
        <Box sx={{
            display:"flex",
            gap:"10px",
                alignItems:"center",
                backgroundColor:"#FFF8D6",
                width:"50vw",
                padding:"15px",
                position:"relative",
                left:"50%",
                mt:"1rem",
                borderTop:"1px solid #FFCB66",
                borderRight:"1px solid #FFCB66",
                borderBottom:"1px solid #FFCB66",
                transform:"translateX(-50%)",
                "@media (max-width: 625px)":{
                    width:"80vw",
                    }
                    }}>
                <Box sx={{backgroundColor:"#A55A0F", width:"5px", height:"calc(100% + 2px)", position:"absolute", left:"-5px"}}> </Box>
                <InfoIcon sx={{color:"#A55A0F", alignSelf:"start", mt:"3.5px"}}/>
                <Typography sx={{
                    width:"100%",
                    fontSize:"18px",
                    margin:"0 auto !important",
                    color:"#1D252C",
                    lineHeight:"28px",
                    "@media (max-width: 625px)":{
                        fontSize:"16px",
                        width:"335px",
                        lineHeight:"24px"
                    }}}>
                    {msg}
                </Typography>
            </Box>
    )
};
export default WarningBox;
