import React, { MutableRefObject } from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";

import SmallBankIDLogo from "./images/SmallBankIDLogo";
import CheckMarkIcon from "./images/CheckMarkIcon";
import InfoIcon from "./images/InfoIcon";
import * as CustomMessages from "./CustomMessages.json";


interface BankIdThisDeviceLoginProps {
  bankIdOpened: boolean;
  loginFailed: boolean;
  setBankIdOpened: (opened: boolean) => void;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  startBankIdLogin: () => Promise<void>;
  bankIdUrl: MutableRefObject<string>;
  userMessage: string;
  loginStatus:string;
  setLoginMethod:(loginMethod: string) => void;
  sign:string;
}


const BankIdThisDeviceLogin: React.FC<BankIdThisDeviceLoginProps> = ({
  bankIdOpened,
  loginFailed,
  setBankIdOpened,
  onBackClick,
  startBankIdLogin,
  bankIdUrl,
  userMessage,
  loginStatus,
  setLoginMethod,
  sign
}) => {
  const cancelBtnText=sign=="true"?"Avbryt signering":"Avbryt inloggning";
  const headingText=sign=="true"?"Signera med BankID":"Identifiera dig med BankID";
  const sucessText=sign=="true"?"Signeringen lyckades":"Identifieringen lyckades";
  const [mobilePending, setMobilePending]=React.useState(false);
  const onOpenBankIdClick = async (
    _event: any
    ): Promise<void> => {
        setMobilePending(true);      
      await startBankIdLogin();
      setBankIdOpened(true);
      window.location.assign(bankIdUrl.current);
    };

    const openBankId=async()=>{
      await startBankIdLogin();
      setBankIdOpened(true);
      window.location.assign(bankIdUrl.current);
    }

  React.useEffect(() => {
    const mobile=window.innerWidth < 900;
    console.log(mobile);

    if (!mobile && !bankIdOpened){
       openBankId();
    }
    getMsg();
  },[loginFailed, bankIdOpened]);

  const [displayMessage, setDisplayMessage] = React.useState("");
  const [displayMessageHeading, setDisplayMessageHeading] = React.useState("");

  const getMsg=()=>{
    let msg:string="";
    switch (userMessage) {
      case CustomMessages.startFailed.Default:
        setDisplayMessage(CustomMessages.startFailed.Custom)
        setDisplayMessageHeading(CustomMessages.startFailed.Heading)
          break;

      case CustomMessages.certificateErr.Default:
        setDisplayMessage(CustomMessages.certificateErr.Custom)
        setDisplayMessageHeading(CustomMessages.certificateErr.Heading)
          break;

      case CustomMessages.expiredTransaction.Default:
        setDisplayMessage(CustomMessages.expiredTransaction.Custom)
        setDisplayMessageHeading(CustomMessages.expiredTransaction.Heading)
          break;

      case CustomMessages.userCancel.Default:
        setDisplayMessage(CustomMessages.userCancel.Custom)
        setDisplayMessageHeading(CustomMessages.userCancel.Heading)
          break;
    
      default:
        setDisplayMessage(userMessage)
        setDisplayMessageHeading("Något gick fel")
        break;
      }  
  }


  return (
    <>
     {/* DESKTOP START*/}
     <Box sx={{
      display:{
       xs:"none",
       md:"flex",
       flexDirection:"column"
      }}}>
        {!loginFailed &&
          <Typography variant="h1" sx={{fontSize:"48px", lineHeight:"52px", mb:"15px",color:"#1D252C", fontWeight:"500"}}>Startar BankID-appen</Typography>        
        }
          {!loginFailed &&
              (bankIdOpened  && loginStatus==="userSign"? (
                <>
                  <Typography variant="h1" sx={{fontSize:"48px", lineHeight:"52px", color:"#1D252C", fontWeight:"500"}}>Skriv in din säkerhetskod i BankID-appen</Typography>
                  <CircularProgress sx={{m:"50px auto 65px auto"}}/>
                </>
                ) : 
                (
                  <>
                  <Typography sx={{
                    fontSize:"18px",
                    margin:"0 auto !important",
                    color:"#1D252C",
                    "@media (max-width: 625px)":{
                      fontSize:"16px",
                      mb:"30px",
                      width:"335px",
                      lineHeight:"24px"
                    }
                    }}>
                    Om appen inte startar automatiskt, 
                    <Button disableRipple={true}
                    sx={{
                      fontSize:"18px",
                      lineHeight:"28px",
                      fontWeight:"400",
                      textTransform:"none",
                      mb:"5px",
                      color:"#1D252C",
                      textDecoration:"underline",
                      pl:"3px",
                      pr:"3px",
                      height:"28px",
                      "&:hover":{
                        color:"#677079",
                        textDecoration:"underline",
                        background:"rgba(0,0,0,0)"
                      },
                      "&:visited":{
                        color:"#677079",
                        textDecoration:"underline",
                        background:"rgba(0,0,0,0)"
                      },
                      "&:focus-visible":{
                        outline: "2px #1D252C solid", /*middle line*/
                        outlineOffset: 0,/*middle line*/
                        boxShadow: "0 0 0 6px #BBCBD750",/*outer line*/
                        borderRadius:0,
                        mr:"3px",
                        ml:"3px"
                      },
                      "@media (max-width: 625px)":{
                        fontSize:"16px"
                      }
                    }} 
                      onClick={onOpenBankIdClick}>
                       klicka här 
                      </Button> 
                      för att försöka igen.
                  </Typography>
                  <CircularProgress sx={{m:"50px auto 65px auto !important"}}/>
                  </>
            ))} 
            {loginFailed && loginStatus!=="complete" &&
              <Box sx={{
                width:"550px",
                boxSizing:"border-box",
                padding:"20px",
                backgroundColor:"#FFEDEB",
                textAlign:"center",
                m:"0 auto !important",
                "@media (max-width: 625px)":{
                  width:"345px"
                }
                }}>
                <InfoIcon sx={{width:"26px"}}/>
                  <Typography variant="h3" sx={{
                    fontSize:"24px",
                    lineHeight:"32px",
                    fontWeight:500,
                    "@media (max-width: 625px)":{
                      fontSize:"21px !important",
                      lineHeight:"30px !important",
                    }
                    }}>{displayMessageHeading}</Typography>
                  <Typography sx={{
                    fontSize:"18px",
                    lineHeight:"28px",
                    "@media (max-width: 625px)":{
                      fontSize:"16px",
                      lineHeight:"24px",
                    }
                    }}>{displayMessage}</Typography>
                <Button disableRipple={true} sx={{
                  height:"50px",
                  backgroundColor:"#1D3442",
                  borderRadius:"100px",
                  textTransform:"uppercase",
                  color:"white",
                  p:"8px",
                  width:"183px",
                  m:"25px auto 20px auto",
                  fontWeight:600,
                  letterSpacing:0.7,
                  "&:hover":{
                    backgroundColor:"#2C4E67"
                  },
                  "&:focus-visible":{                 
                    outline: "2px #1D252C solid", //middle line
                    outlineOffset: 0,/*middle line*/
                    boxShadow: "0 0 0 6px #BBCBD750", //outer line
                    border:"2px solid white"  /*inner line*/
                  },
                  }}
                  onClick={()=>history.back()}
                  >Försök igen</Button>
              </Box>
            }
            {!loginFailed&&
              <Button
                variant="outlined"
                fullWidth={false}
                disableRipple={true} disableTouchRipple={true}
                sx={{ 
                  height:"50px",
                  m:"0 auto !important",
                  padding:"8px",
                  width:"246px",
                  textTransform:"uppercase",
                  border:"2px solid #031E40",
                  borderRadius:"100px",
                  color:"#1D3442",
                  fontSize:"16px",
                  fontWeight:600,
                  letterSpacing:0.7,
                  "&:hover":{
                    backgroundColor:"#878D9726",
                    border:"2px solid #031E40"
                  },
                  "&:focus-visible":{
                    outline: "2px #1D252C solid", /*middle line*/
                    outlineOffset: "-6px",/*middle line*/
                    boxShadow: "0 0 0 6px #BBCBD750", /*outer line*/
                    border:"2px solid #1D252C" /* inner line     */  
                  },
                  "@media (max-width: 625px)":{
                    fontSize:"15px",
                  }
                }}
                onClick={onBackClick}
                >
                    {cancelBtnText}
              </Button>
            }
      </Box>
    {/* DESKTOP END*/}
    {/* MOBILE START */}
      <Box sx={{
        maxWidth:"80vw",
        m:"0 auto !important",
        display:{
          xs:"flex",
          flexDirection:"column",
          alignItems:"center",
          md:"none"
        }
      }}>
        {mobilePending && !loginFailed &&
            (bankIdOpened ? (
                <>
                <Typography variant="h1" sx={{
                  fontSize:"32px",
                  lineHeight:"36px",
                  mb:"10px",  
                  fontWeight:500,
                  width:"345px",
                  color:"#1D252C",
                }}>
                  Skriv in din säkerhetskod i BankID-appen
                </Typography>
                <CircularProgress sx={{m:"50px auto 65px auto"}}/>
              </>
              ) : 
              <>
              <Typography variant="h1" sx={{
                fontSize:"32px",
                lineHeight:"36px",
                mb:"10px",
                color:"#1D252C",
                fontWeight:500,
              }}>
                Startar BankID-appen
              </Typography>
              <CircularProgress sx={{m:"50px auto 65px auto"}}/>
            </>
        )}
        {!mobilePending && loginStatus!=="complete" && !loginFailed &&
        <>
          <Typography variant="h1" sx={{fontSize:"32px", lineHeight:"36px", fontWeight:500, mb:"10px",color:"#1D252C", maxWidth:"90vw"}}>{headingText}</Typography>
          <Typography sx={{fontSize:"16px", lineHeight:"24px", width:"335px", maxWidth:"90vw", color:"#1D252C"}}>Tryck på knappen nedan för att starta BankID-appen i din mobil.</Typography>
          <Button disableRipple={true} disableTouchRipple={true}
            sx={{
            backgroundColor:"#1D3442",
            borderRadius:"100px",
            textTransform:"uppercase",
            color:"white",
            p:"8px",
            width:"345px",
            maxWidth:"90vw",
            height:"50px",
            m:"25px auto 20px auto",
            left: "50%",
            fontSize:"15px",
            lineheight:"18px",
            letterSpacing:"0.7px",
            fontWeight:"600",
            transform: "translateX(-50%)",
            "&:hover":{
              backgroundColor:"#2C4E67"
            },
            "&:focus-visible":{
              outline: "2px #1D252C solid", //middle line
              outlineOffset: 0,/*middle line*/
              boxShadow: "0 0 0 6px #BBCBD750", //outer line
              border:"2px solid white"  /*inner line*/
            },
          }}
          onClick={onOpenBankIdClick}
          ><SmallBankIDLogo sx={{mt:"5px"}}/>Öppna mobilt bankid</Button>
           <Button onClick={()=>setLoginMethod("mobile")} disableRipple={true} disableTouchRipple={true}
           sx={{
            m:"0 auto !important",
            textTransform:"none",
            fontSize:"18px",
            textDecoration:"underline",
            display:{ xs:"flex", md:"none"},
            color:"#1D252C",
            fontWeight:"400",
            height:"28px",
            "&:hover":{
              color:"#677079",
              textDecoration:"underline",
              background:"rgba(0,0,0,0)"
            },
            "&:visited":{
              color:"#677079",
              textDecoration:"underline",
              background:"rgba(0,0,0,0)"
            },
            "&:focus-visible":{
              outline: "2px #1D252C solid", /*middle line*/
              outlineOffset: 0,/*middle line*/
              boxShadow: "0 0 0 6px #BBCBD750 ",/*outer line*/
              borderRadius:0
            },
            "@media (max-width: 625px)":{
              fontSize:"16px"
            }
            }}> 
            Använd BankID på en annan enhet
          </Button>
        </>
        }
        {loginFailed && loginStatus!=="complete" &&
        <>
          <Typography variant="h1" sx={{fontSize:"32px", lineHeight:"36px", fontWeight:500, color:"#1D252C"}}>{headingText}</Typography>
          <Box sx={{
          width:"550px",
          maxWidth:"80vw",
          boxSizing:"border-box",
          padding:"20px",
          backgroundColor:"#FFEDEB",
          textAlign:"center",
          m:"30px auto 0 auto!important",
          "@media (max-width: 625px)":{
            width:"345px"
          }
          }}>
          <InfoIcon sx={{width:"26px"}}/>
            <Typography variant="h3" sx={{
              fontSize:"24px",
              lineHeight:"32px",
              color:"#1D252C",
              fontWeight:500,
              "@media (max-width: 625px)":{
                fontSize:"21px !important",
                lineHeight:"30px !important",
              }
              }}>{displayMessageHeading}</Typography>
            <Typography sx={{
              fontSize:"18px",
              lineHeight:"28px",
              color:"#1D252C",
              "@media (max-width: 625px)":{
                fontSize:"16px",
                lineHeight:"24px",
              }
              }}>{displayMessage}</Typography>
          <Button disableRipple={true} sx={{
            height:"50px",
            backgroundColor:"#1D3442",
            borderRadius:"100px",
            textTransform:"uppercase",
            color:"white",
            p:"8px",
            width:"183px",
            m:"25px auto 20px auto",
            fontWeight:600,
            letterSpacing:0.7,
            "&:hover":{
              backgroundColor:"#2C4E67"
            },
            "&:focus-visible":{
              outline: "2px #1D252C solid", //middle line
              outlineOffset: 0,/*middle line*/
              boxShadow: "0 0 0 6px #BBCBD750", //outer line
              border:"2px solid white"  /*inner line*/
            },
            }}
            onClick={()=>history.back()}
            >Försök igen</Button>
        </Box>
        </>
        }
        {mobilePending && loginStatus !=="userSign" && !loginFailed &&
          <>
          {!loginFailed&&
            <Button
            variant="outlined"
            fullWidth={false}
            disableRipple={true} disableTouchRipple={true}
            sx={{ 
              height:"50px",
              m:"0 auto !important",
              padding:"8px",
              width:"246px",
              textTransform:"uppercase",
              border:"2px solid #031E40",
              borderRadius:"100px",
              color:"#1D3442",
              fontSize:"16px",
              fontWeight:600,
              letterSpacing:0.7,
              "&:hover":{
                backgroundColor:"#878D9726",
                border:"2px solid #031E40"
              },
              "&:focus-visible":{
                outline: "2px #1D252C solid", /*middle line*/
                outlineOffset: "-6px",/*middle line*/
                boxShadow: "0 0 0 6px #BBCBD750", /*outer line*/
                border:"2px solid #1D252C" /* inner line     */  
              },
            "@media (max-width: 625px)":{
              fontSize:"15px",
            }
          }}
          onClick={onBackClick}
          >
          {cancelBtnText}
        </Button>
          }
      </>
        }
      </Box>
      {/* MOBILE END */}
   {loginStatus==="complete"&&
   <>
      <Typography variant="h1" sx={{fontSize:"32px", lineHeight:"36px", fontWeight:500, color:"#1D252C"}}>{headingText}</Typography>
      <Box sx={{
        width:"550px",
        height:"162px",
        m:"50px auto 0 auto !important",
        boxSizing:"border-box",
        padding:"20px",
        backgroundColor:"#EEF8E2",
        textAlign:"center",
        "@media (max-width: 625px)":{
          width:"345px",
          height:"132px"
        }
        }}>
          <CheckMarkIcon sx={{width:"26px"}}/>
          <Typography variant="h3" sx={{
            fontSize:"24px",
            lineHeight:"32px",
            color:"#1D252C",
            fontWeight:500,
            "@media (max-width: 625px)":{
              fontSize:"21px",
              lineHeight:"30px",
            }
            }}>{sucessText}</Typography>
          <Typography sx={{
            fontSize:"18px",
            lineHeight:"28px",
            color:"#1D252C",
            "@media (max-width: 625px)":{
              fontSize:"16px",
              lineHeight:"24px",
            }
            }}>Du skickas nu vidare.</Typography>
      </Box>
    </>
   }
    </>
  );
};

export default BankIdThisDeviceLogin;
