import React from "react";
import BankIdFrame from "./BankIdFrame";
import {
  Container,
  Stack,
  Typography,
  Link as MaterialLink,
  Box,
  Button,
} from "@mui/material";
import NavLogo from "./images/NavLogo";
import CaretLeft from "./images/CaretLeft";
import WarningBox from "./WarningBox";

const AuthRoot: React.FC<{}> = () => {
  return (
    <Container
      maxWidth={false}
      sx={{padding:"0 !important"}}
     >
       <Stack
            sx={{
              width: {
                xs: "auto",
                sm: 625,
                md: 936,
                lg: 1128,
                xl: 1128,
              },
            }}
            spacing={{ xs: 1, md: 2 }}
          ></Stack>
      <Box
      sx={{
        display:"flex",
        height:"120px",
        width:"100%",
        boxShadow:"0 2px 12px -1px #1D252C1A",
        "@media (max-width: 625px)":{
          height:"70px"
        }
      }}
      >
       <Button disableRipple={true} sx={{"&:focus-visible":{borderBottom:"2px solid #1D252C"}}} onClick={()=>history.back()}>
        <CaretLeft sx={{height:"24px", position:"absolute !important", top:"48px", left:"40px", "&:focus-visible":{textDecoration:"underline"}, "@media (max-width: 625px)":{left:"10px", top:"20px"}}}/>
       </Button>
        <NavLogo sx={{height:"49px", position:"absolute", top:"30px", left:"50%", transform:"translateX(-50%)",  "@media (max-width: 625px)":{height:"40px", top:"10px"}}}/>
      </Box>
        <WarningBox/>
        <BankIdFrame/>
    </Container>
  );
};

export default AuthRoot;
