import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import AuthRoot from "./AuthRoot";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(3, 30, 64, 1)",
    },
  },

  typography:{
    fontFamily:[
      'district-pro',
      'sans-serif'
    ].join(','),
  },
  
});

const App: React.FC<{}> = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AuthRoot />
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
