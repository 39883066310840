import React from "react";
import { Box, BoxProps } from "@mui/material";

const CheckMarkIcon: React.FC<BoxProps> = (props) => {
  return (
    <Box component="img" src="/images/CheckMark.svg" alt="Info icon" {...props} />
  );
};

export default CheckMarkIcon;
