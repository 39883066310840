import React from "react";
import { Box, BoxProps } from "@mui/material";

const NavLogo: React.FC<BoxProps> = (props) => {
  return (
    <Box component="img" src="/images/NavLogo.svg" alt="Wallenstam logo" {...props}/>
  )
};

export default NavLogo;
