import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      {/* Outer Circle Path */}
      <path fillRule="evenodd" clipRule="evenodd" d="M3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16ZM27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27C22.0751 27 27 22.0751 27 16Z" />
      {/* Vertical Line Path */}
      <path d="M16 9.66666C16.5063 9.66666 16.9247 10.0429 16.9909 10.531L17 10.6667V17.3333C17 17.8856 16.5523 18.3333 16 18.3333C15.4937 18.3333 15.0753 17.9571 15.0091 17.469L15 17.3333L15 10.6667C15 10.1144 15.4477 9.66666 16 9.66666Z" />
      {/* Bottom Circle */}
      <circle cx="15.9998" cy="21.3333" r="1.33333" />
    </SvgIcon>
  );
};

export default InfoIcon;
