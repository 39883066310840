import React from "react";
import { Box, BoxProps } from "@mui/material";

const CaretLeft: React.FC<BoxProps> = (props) => {
  return (
    <Box component="img" src="/images/CaretLeft.svg" alt="Caret left" {...props} />
  );
};

export default CaretLeft;
