import React from "react";
import { Box, BoxProps } from "@mui/material";

const SmallBankIDLogo: React.FC<BoxProps> = (props) => {
  return (
    <Box {...props}>
        <Box component="img" src="/images/BankID_small.svg" alt="BankID logo"/>
    </Box>
  )
};

export default SmallBankIDLogo;
