import React from "react";
import { Box, BoxProps } from "@mui/material";

const BottomCornerBorderQRCode: React.FC<BoxProps> = (props) => {
  return (
    <Box {...props}>
        <Box component="img" src="/images/LeftBottomCorner.svg" alt="Wallenstam logo"/>
        <Box component="img" src="/images/RightBottomCorner.svg" alt="Wallenstam logo"/>
    </Box>
  )
};

export default BottomCornerBorderQRCode;
